import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["primaryAliquots", "totalAliquots", "primaryAliquotsWarning", "useCollectionContainerToggle"]
  static values = {
    configId: String
  };

  collectionStepModal(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const collection_id = event.target.dataset.collectionId
      $.ajax({
        type: "GET",
        url: `/configuration/${this.configIdValue}/collection_step_modal/?collection_id=${collection_id}`,
        dataType: "script"
      });
    }
  }
  
  aliquotPlanModal(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const collection_id = event.target.dataset.collectionId
      const aliquot_plan_id = event.target.dataset.aliquotPlanId
      $.ajax({
        type: "GET",
        url: `/configuration/${this.configIdValue}/aliquot_plan_modal/?collection_id=${collection_id}&aliquot_plan_id=${aliquot_plan_id}`,
        dataType: "script"
      });
    }
  }

  verifySubmitAliquotPlan(event) {
    event.preventDefault();
    var primary = this.primaryAliquotsTarget.value
    var total = this.totalAliquotsTarget.value
    var use_collection_container = this.useCollectionContainerToggleTarget.value
    if (use_collection_container == "0" && primary > total){
      $(this.primaryAliquotsWarningTarget).removeClass("d-none")
      $(this.primaryAliquotsTarget).addClass('border border-warning')
    } else {
      $("#aliquot_plan_form").submit()
    }
  }
  
  sampleHandlingModal(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const aliquot_plan_id = event.target.dataset.aliquotPlanId
      $.ajax({
        type: "GET",
        url: `/configuration/${this.configIdValue}/sample_handling_modal/?aliquot_plan_id=${aliquot_plan_id}`,
        dataType: "script"
      });
    }
  }

  manageConfigStepsModal(event) {
    event.preventDefault();
    if ($("#hide_config_steps").is(":checked")) {
      localStorage.setItem('hide-config-steps-modal', "true");
    }
    $('#config_steps_modal').modal('toggle')
  }
}
