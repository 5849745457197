import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["checkAll", "tableRow", "actionDropdown", "actionCount", "actionsButton", "paginationOption"]
  }

  static get values() {
    return {
      query: String,
      updateUrl: {
        type: Boolean,
        default: true,
      }
    };
  }

  initialize() {
    this.rowCount = 0;
    this.enabledRowCount = 0;
    this.checkedItems = new Set();
  }

  connect() {
    this.rowCount = this.tableRowTargets.length;
    this.enabledRowCount = this.tableRowTargets.filter((row) => {
      const checkbox = row.querySelector('input[type="checkbox"]');
      return checkbox ? !checkbox.disabled : false;
    }).length;
    this.checkedItems = new Set(this.tableRowTargets.filter((row) => {
      const checkbox = row.querySelector('input[type="checkbox"]');
      return checkbox ? checkbox.checked : false;
    }).map((row) => row.dataset.id));

    this.updateControls();

    this.rendered();
    this.setPaginationOptionLinks()
  }

  rendered() {
    $('.selectpicker').selectpicker({
      noneSelectedText: ''
    });

    this.manageFilterPills()
  }

  manageFilterPills(){
    let hasChanges = false;
    $('.pill-input').on('change.bs.select', function() {
      hasChanges = true;
    });

    // submit filters when dropdowns are hidden
    $('.pill-input').on('hidden.bs.select', function(e) {
      if (hasChanges){
        e.preventDefault;
        this.form.requestSubmit();
      }
      hasChanges = false;
    });
    
    // submit filter when user hits enter in a search
    $('.search').on('keyup', function(e) {
      if(e.keyCode == 13) {
        e.preventDefault();
        this.form.requestSubmit();
      }
    })

    // submit filter when search loses focus
    $('.search').on('focusout', function(e){
      if(e.currentTarget.value != '') {
        e.preventDefault();
        this.form.requestSubmit();
      }
    })

    $('.turbo-table').tooltip({
      selector: '[data-toggle="tooltip"]'
    })

    $('.input-daterange').datepicker({
      clearBtn: true,
      format: 'dd-M-yyyy',
      keepEmptyValues: true,
      autoclose: true
    }).on('changeDate', (ev) => {
      this.datepickerUpdated(ev);
    });
  }

  datepickerUpdated(ev) {
    const daterangeSection = ev.currentTarget;
    const inputs = [...daterangeSection.querySelectorAll('.form-control')];
    const dates = inputs.map((input) => input.value);
    document.querySelector("#date-range-values").value = `${dates[0]};${dates[1]}`;

    // when the dates in the datepicker are entered/updated, only submit the form if the second date is present
    // because of the way the daterange element works, the form has to be submitted in the context of the individual form inputs, not the daterange container
    if (dates[1] != '') {
      const input = inputs[1]
      input.form.submit();
    }
  }

  startedLoading() {
    this.element.classList.add('turbo-table-loading');
  }

  finishedLoading() {
    this.element.classList.remove('turbo-table-loading');
  }

  queryValueChanged() {
    if (!this.updateUrlValue) return;

    let params = new URLSearchParams(this.queryValue);

    let url = new URL(window.location.href);
    url.search = params.toString();

    Turbo.navigator.history.replace(url);
  }

  notifyCheckedRowsChanged() {
    this.dispatch("checkedRows", {
      detail: {
        ids: this.checkedItems,
      }
    });
  }

  notifyCheckedAll() {
    this.dispatch("checkedRows", {
      detail: {
        ids: this.checkedItems,
        check_all: true,
      }
    });
  }

  toggle({ params: { id } }) {
    if (this.checkedItems.has(id)) {
      this.checkedItems.delete(id);
    } else {
      this.checkedItems.add(id);
    }

    this.updateControls();
    this.notifyCheckedRowsChanged();
  }

  checkAll() {
    if (this.enabledRowCount === 0) {
      return;
    }

    const checked = this.checkedItems.size === 0;

    this.tableRowTargets.forEach((row) => {
      const id = row.dataset.id;
      const checkbox = row.querySelector('input[type="checkbox"]');

      if (checked && !checkbox.disabled) {
        this.checkedItems.add(id);
        checkbox.checked = true;
      } else {
        this.checkedItems.delete(id);
        checkbox.checked = false;
        row.classList.remove("active")
      }
    });

    this.updateControls();
    this.notifyCheckedAll();
  }
  
  updateControls() {
    // Check all target is only missing when all controls are missing.
    if (!this.hasCheckAllTarget) {
      return;
    }

    this.checkAllTarget.checked = this.enabledRowCount > 0 && this.checkedItems.size === this.rowCount;
    this.checkAllTarget.indeterminate = this.checkedItems.size !== 0 && this.checkedItems.size !== this.rowCount;
    this.checkAllTarget.disabled = this.enabledRowCount === 0;

    if (this.hasActionCountTarget) {
      this.actionCountTarget.innerHTML = this.checkedItems.size;
      this.actionDropdownTarget.disabled = this.checkedItems.size === 0;
    }

    const actionsButtonDisabled = this.checkedItems.size > 1;
    this.actionsButtonTargets.forEach(function (button) {
      const buttonParent = button.parentNode;

      if (actionsButtonDisabled) {
        button.disabled = true;
        button.style.pointerEvents = 'none';
        buttonParent.dataset.toggle = 'tooltip';
        buttonParent.dataset.placement = 'left';
        buttonParent.title = 'Use the "Actions for checked items" button';
      } else {
        button.disabled = false;
        button.style.pointerEvents = '';
        buttonParent.dataset.toggle = '';
        buttonParent.title = '';
      }
    });
  }

  submitChecked(event) {
    event.detail.perform(this.checkedItems);
  }

  setPaginationOptionLinks(){
    let params = new URLSearchParams(this.queryValue);
    let url = new URL(window.location.href);
    this.paginationOptionTargets.forEach((element)=>{
      let option = element.dataset.option
      params.set("per_page", option)
      url.search = params.toString();
      element.href = url.href
    })
  }

  loadingAnimation(){
    document.getElementById('turbo-table-frame').ariaBusy = true
  }
}
