import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    planId: String
  }
  
  static targets = [];

  connect(){
  }
  
  toggleCollapseIcon(e){
    var report_id = e.target.closest(".report-toggle").getAttribute("data-report-id")
    $(`#toggle-icon-${report_id}`).toggleClass('fa-chevron-up');
    $(`#toggle-icon-${report_id}`).toggleClass('fa-chevron-down');
  }

  submitForm(e){
    e.target.closest("form").submit()
  }
}
