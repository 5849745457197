import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea-autogrow"
export default class extends Controller {
  connect() {
    this.element.style.overflow = 'hidden';
    if (!this.element.classList.contains('start-collapsed')){
      this.grow();
    }
  }

  grow() {
    this.element.style.height = 'auto';
    this.element.style.height = `${this.element.scrollHeight}px`;
  }
}
